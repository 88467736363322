import React from 'react'
import Marquee from 'react-fast-marquee';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../utils/config';
import { Link } from 'react-router-dom';
// ********

// ***********
const ClientMarkque = () => {
    const [AllClient, setAllClient] = useState([]);

    useEffect(() => {
        const fetchClientList = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getAllClient`);
                setAllClient(response.data);
            } catch (error) {
                console.error("Error fetching about data:", error);
            }
        };
        fetchClientList();
    }, []);
    return (
        <section className="p-10 sec-partners">
            <div class="marquee">
                <Link to="/clients">
                    <ul>
                        <Marquee direction="left" speed={100} delay={3} pauseOnHover={true}>
                            {AllClient.map((client, index) => {
                                return (
                                    // <li key={index}><img src={`${BaseUrl}/client-image/${client.ClientImage}`} alt={client.ClientName}/></li>
                                    <li key={index}><span className='cilentMar'>{client.ClientName}</span></li>
                                );
                            })}
                        </Marquee>
                    </ul>
                </Link>
            </div>
        </section>

    )
}

export default ClientMarkque