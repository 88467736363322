import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { ApiUrl } from '../Component/utils/config'
import { NavLink } from 'react-router-dom'
import mainLogo from '../assets/images/logo.svg';
import social1 from '../assets/images/socials/01.png';
import social2 from '../assets/images/socials/02.png';
import social3 from '../assets/images/socials/03.png';
import social4 from '../assets/images/socials/04.png';
// import sign1 from '../assets/images/gif/sign-white.gif';
import sign2 from '../assets/images/gif/sign-yellow.gif';
import tpnlogo from '../assets/images/ft-accer02.png';
import tpnlogoGold from '../assets/images/ft-accer02b.png';
import Swal from 'sweetalert2'
const Footer = () => {
    const [IsHover, setIsHover] = useState(false);
    const [FooterContent, setFooterContent] = useState([]);
    const [formData, setFormData] = useState({
        subsmail: '',
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchMediaList = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getFooterContent`);
                setFooterContent(response.data);
            } catch (error) {
                console.error("Error fetching Media List:", error);
            }
        };
        fetchMediaList();
    }, []);
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const validateForm = (data) => {
        let errors = {};
        if (!data.subsmail.trim()) {
          errors.subsmail = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.subsmail.trim())) {
          errors.subsmail = 'Invalid email address';
        }
        return errors;
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formData);
        const errors = validateForm(formData);

        // if (Object.keys(errors).length === 0 && recaptchaToken) {
        if (Object.keys(errors).length === 0) {
            const ApiURL12345 = `${ApiUrl}/saveSubcriberMail`;
            axios.post(ApiURL12345, formData)
                .then(response => {
                    Swal.fire({
                        icon: "success",
                        title: "Thank you for  your subscription!",
                        // text: "Our team will reach out ASAP. Peace of mind is coming your way.",
                        customClass: {
                            popup: 'swal-custom-popup',
                            title: 'swal-custom-title',
                            content: 'swal-custom-content',
                            footer: 'swal-custom-footer'
                        },
                        css: {
                            popup: 'background-color: black;',
                            title: 'color: yellow;',
                            content: 'color: white;',
                            footer: 'color: white;'
                        },
                        confirmButtonText: 'OK',
                        confirmButtonColor: 'green',
                        allowOutsideClick: false, // Prevents closing the dialog by clicking outside
                        allowEscapeKey: false, // Prevents closing the dialog by pressing ESC key
                        allowEnterKey: true, // Allows closing the dialog by pressing Enter key
                        showCancelButton: false,
                        focusConfirm: true,
                        preConfirm: () => {
                            // This function is called when the user clicks the "OK" button
                            window.location.reload(); // Refresh the page
                        }
                    });
                })
                .catch(error => {
                    console.error('Error occurred while sending data:', error);
                });
        } else {
            setErrors({ ...errors, recaptcha: 'Please complete the reCAPTCHA verification' });
        }
    };

    return (
        <>
            <footer className="p-100 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="ft-info">
                                <img src={mainLogo} />
                                <p className="text-white1"><div dangerouslySetInnerHTML={{ __html: FooterContent?.cms_long_title }} /></p>
                            </div>
                            <div className="ft-cont">
                                <img src={sign2} />
                                {/* <h3>GET IN TOUCH</h3>
                                <a href="mailto:connect@yveslaniel.com">connect@yveslaniel.com</a>
                                <a href="tel:+15144645600">+1 (514) 464-5600</a> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ft-all-info">
                                <div className="quick-links">
                                    <NavLink to="/services" class="active">Services </NavLink>
                                    <NavLink to="/team">Our Team</NavLink>
                                    <NavLink to="/filmography">Filmography </NavLink>
                                    <NavLink to="/clients">They TRUST Us</NavLink>
                                    <NavLink to="/media">Media</NavLink>
                                    <NavLink to="/founder">Founder</NavLink>
                                    <NavLink to="/contact-us"> Get in Touch</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5">
                            <div className="ft-all-info Follow-subs">
                                <div className="ft-cont">
                                    <h3>Follow us on</h3>
                                    <div className="social-info">
                                        <a href="https://www.linkedin.com/company/vfx-outsourcing-partner" target="_blank"><img
                                            src={social1} /></a>
                                        {/* <a href="javascript:void(0)" target="_blank"><img
                                            src={social2} /></a>
                                        <a href="javascript:void(0)" target="_blank"><img
                                            src={social3} /></a> */}
                                        <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank"><img
                                            src={social4} /></a>
                                    </div>
                                </div>

                                <div className="ft-cont" style={{ marginBottom: '50px' }}>
                                    <h3>STAY CONNECTED</h3>
                                    <form action="javascript:void(0)" method="post" onSubmit={handleSubmit}>
                                        <div className="mail-subscrip">
                                            <input type="text" className="form-control" name="subsmail" id="subsmail" placeholder="expample@gmail.com" value={formData.subsmail} onChange={handleChange} autoComplete='off'/>
                                            <button type="submit"><i className="fa-solid fa-arrow-right-long"></i></button>
                                        </div>
                                        {errors.subsmail && <div style={{ color: 'red',fontSize:'12px' }}>{errors.subsmail}</div>}
                                    </form>
                                </div>
                                <div className="" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    {!IsHover ? (
                                        <img src={tpnlogo} />
                                    ) : (
                                        <img src={tpnlogoGold} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="sec-ft-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright-info">
                                <ul>
                                    <li><a href="javascript:void(0)">Copyright ©2024 <b> Yves Laniel</b></a></li>
                                    <li>
                                        <a href="https://www.brandwizz.com" target="_blank">Design by: <b>Brandwizz</b></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer