import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate, NavLink } from "react-router-dom";

import mainLogo from '../assets/images/logo.svg';
import menuHam from '../assets/images/menu.png';
import menuCls from '../assets/images/close.png';
import linkIcon from '../assets/images/socials/001.png'
import candyIcon from '../assets/images/socials/004.png';
const Header = () => {
    const [WenBigMenu, setWenBigMenu] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [onlyMobile, setOnlyMobile] = useState(false);
    const webMenu = () => {
        setWenBigMenu(!WenBigMenu);
    }
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const openNav = () => {
        // alert("Open Mob Menu");
        setOnlyMobile(!onlyMobile);
    }
    const closeNav = () => {
        setOnlyMobile(false);
    }

    return (
        <header className={`site-header ${scrolling ? 'hide navbar-fixed' : ''}`} id="myHeader">
            
            <div className="container">
                <div className="navigationWrap">
                    <div className="menuWrap">
                        <div className="logobox">
                            <Link to="/">
                                <img className="whiteLogo" width="114" height="54" loading="lazy" src={mainLogo} alt="" />
                            </Link>
                        </div>
                        <div class="navbarMain onlyDesktop">
                            <ul>
                                <li><NavLink to="/services" class="active">Services </NavLink></li>
                                <li><NavLink to="/team">Our Team</NavLink></li>
                                <li><NavLink to="/filmography">Filmography </NavLink></li>
                                <li><NavLink to="/clients">They TRUST Us</NavLink></li>
                                {/* <li><NavLink to="/clients">Clients</NavLink></li> */}
                                <li><NavLink to="/media">Media</NavLink></li>
                                <li><NavLink to="/founder">Founder</NavLink></li>
                                <li><NavLink to="/contact-us"> Get in Touch</NavLink></li>
                            </ul>

                        </div>
                        {/* <div className="navbarMain onlyDesktop">
                            <ul>
                                <li><a href="javascript:void(0)" className="get-touch"> Get in Touch</a></li>

                                <li className="menu-ham">
                                    <div className={WenBigMenu ? 'button_container active' : 'button_container'} id="toggle" onClick={webMenu}>
                                        <span className="top"></span>
                                        <span className="bottom"></span>
                                    </div>
                                    <div className={WenBigMenu ? 'overlay open' : 'overlay'} id="overlay">
                                        <nav className="overlay-menu">
                                            <ul>
                                                <li>
                                                    <div className="ft-all-info">
                                                        <div className="ft-cont">
                                                            <h3>Follow us on</h3>
                                                            <div className="social-info">
                                                                <a href="https://www.linkedin.com/in/lanielyves/" target="_blank">
                                                                    <img src="assets/images/socials/01.png" />
                                                                </a>
                                                                <a href="javascript:void(0)" target="_blank">
                                                                    <img src="assets/images/socials/02.png" />
                                                                </a>
                                                                <a href="javascript:void(0)" target="_blank">
                                                                    <img src="assets/images/socials/03.png" />
                                                                </a>
                                                                <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank">
                                                                    <img src="assets/images/socials/04.png" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </li>
                            </ul>
                        </div> */}
                        <div className="onlyMobile">
                            {onlyMobile && (
                                <div id="Sidenav" className={`sidenav ${onlyMobile ? 'mobile-fillwidth' : ''}`}>
                                    <div className="mobileBoxLogo InsideSideBar">
                                        <a href="/">
                                            <img width="118" height="29" loading="lazy" alt="" src={mainLogo} />
                                        </a>
                                    </div>
                                    <a href="javascript:void(0)" className="closebtn" style={{ fontSize: '45px' }} onClick={closeNav}>
                                        <img src={menuCls} />
                                    </a>
                                    <div className="mobmenuList accordion">
                                        <ul className="accordion accordion-flush" id="accordionFlushExampleMobmenu">

                                            <li className="accordion-item"><Link to="/">Home</Link></li>
                                            <li className="accordion-item"><Link to="/services">Services</Link></li>
                                            <li className="accordion-item"><Link to="/team">Our Team</Link></li>
                                            <li className="accordion-item"><Link to="/filmography">Filmography </Link></li>
                                            <li className="accordion-item"><Link to="/clients">They TRUST Us </Link></li>
                                            {/* <li className="accordion-item"><Link to="/clients">Clients</Link></li> */}
                                            <li className="accordion-item"><Link to="/media">Media </Link></li>
                                            <li className="accordion-item"><Link to="/founder">Founder</Link></li>
                                            <li className="accordion-item"><Link to="/contact-us">Get in Touch </Link></li>
                                            {/* <li className="accordion-item"><Link to="/contacts">Contacts </Link></li> */}
                                        </ul>

                                        {/* <div className="get-toch-mob">
                                            <a href="javascript:void(0)" className="get-touch"> Get in Touch</a>
                                        </div> */}

                                        <div className="social-info">
                                            <ul className="socialIcons">
                                                <a href="https://www.linkedin.com/company/vfx-outsourcing-partner" target="_blank">
                                                    <img src={linkIcon} />
                                                </a>
                                                {/* <a href="javascript:void(0)" target="_blank">
                                                    <img src="assets/images/socials/002.png" />
                                                </a>
                                                <a href="javascript:void(0)" target="_blank">
                                                    <img src="assets/images/socials/003.png" />
                                                </a> */}
                                                <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank">
                                                    <img src={candyIcon} />
                                                </a>
                                            </ul>
                                        </div>
                                    </div>


                                </div>

                            )}
                            <div className="sideNaviMob">
                                <span className="hamburgers" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={openNav}>
                                    <img src={menuHam} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header